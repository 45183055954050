import { css } from "emotion";
import React, { ReactNode } from "react";
import { Avatar } from ".";
import { AvatarBase } from "./AvatarBase";

export interface AvatarListProps {
    names: string[];
    count: number;
}

const classes = {
    root: css`
        & > div {
            margin-right: 6px;
        }
    `,
};

export const AvatarList = (props: AvatarListProps) => {
    const { names, count } = props;

    const overflow = names.length - count;
    const overflowText = `+${overflow}`;

    return (
        <div className={classes.root}>
            {names.slice(0, count).map((name) => {
                return <Avatar key={name} name={name} />;
            })}
            {overflow > 0 && <AvatarBase text={overflowText} />}
        </div>
    );
};
