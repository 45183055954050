import { css, cx } from "emotion";
import React, { ReactNode, useState } from "react";
import { Button, ButtonGroup, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

export interface TabsProps {
    tabs: Tab[];
}

export interface Tab {
    title: string;
    renderContent: () => ReactNode;
}

const classes = {
    root: css`
        .tab-content {
            padding-top: 24px;
        }
    `,

    title: css`
        width: 150px;
    `,
};

export const Tabs = (props: TabsProps) => {
    const { tabs } = props;

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    return (
        <div className={classes.root}>
            <ButtonGroup>
                {tabs.map((tab, index) => {
                    return (
                        <Button
                            key={index}
                            outline={true}
                            color="dark"
                            className={cx(classes.title, activeTabIndex === index ? "active" : "")}
                            onClick={() => setActiveTabIndex(index)}
                        >
                            {tab.title}
                        </Button>
                    );
                })}
            </ButtonGroup>
            <TabContent activeTab={activeTabIndex}>
                {tabs.map((tab, index) => {
                    return (
                        <TabPane key={index} tabId={index}>
                            {tab.renderContent()}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
};
