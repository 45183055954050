import { memoize } from "lodash";

export interface AvatarColors {
    backgroundColor: string;
    textColor: string;
}

export const getInitials = memoize(
    (name: string): string => {
        name = (name || "").toUpperCase().replace(/[^a-zA-Z ]/g, "");

        let initials: string;

        if (name.length <= 2) {
            initials = name;
        } else {
            const namePieces = name.split(" ");
            const numberOfWords = namePieces.length;
            const firstInitial = numberOfWords > 0 ? namePieces[0][0] : "";
            const lastInitial = numberOfWords > 1 ? namePieces[numberOfWords - 1][0] : "";
            initials = (firstInitial || "") + (lastInitial || "");
        }

        return initials.length ? initials : "?";
    },
);

export const getBackgroundColor = memoize(
    (name: string): string => {
        if (name.length <= 3) {
            name = name + name + name;
        }

        return stringToColor(name);
    },
);

export const getTextColor = memoize(
    (backgroundColor: string): string => {
        const [r, g, b] = colorToRgb(backgroundColor);
        const isColorLight = r * 0.299 + g * 0.587 + b * 0.114 > 186;
        return isColorLight ? "#000000" : "#FFFFFF";
    },
);

/* tslint:disable:no-bitwise */
function stringToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
    }

    return color;
}
/* tslint:enable:no-bitwise */

function colorToRgb(colorAsHex: string): [number, number, number] {
    if (colorAsHex.indexOf("#") === 0) {
        colorAsHex = colorAsHex.slice(1);
    }

    // convert 3-digit hex to 6-digits.
    if (colorAsHex.length === 3) {
        colorAsHex = colorAsHex[0] + colorAsHex[0] + colorAsHex[1] + colorAsHex[1] + colorAsHex[2] + colorAsHex[2];
    }

    if (colorAsHex.length !== 6) {
        throw new Error("Invalid HEX color");
    }

    const r = parseInt(colorAsHex.slice(0, 2), 16);
    const g = parseInt(colorAsHex.slice(2, 4), 16);
    const b = parseInt(colorAsHex.slice(4, 6), 16);

    return [r, g, b];
}
