import { useEffect, useState } from "react";
import { createResource, Fetch, Resource, ResourceOptions } from "./createResource";

export interface ResourceHook<Input, Value> {
    (input: Input): Value;
    resource: Resource<Input, Value>;
}

export function createResourceHook<Input extends string | number, Value>(
    fetch: Fetch<Input, Value>,
): ResourceHook<Input, Value>;
export function createResourceHook<Input, Value>(
    fetch: Fetch<Input, Value>,
    options: ResourceOptions<Input>,
): ResourceHook<Input, Value>;
export function createResourceHook<Input, Value>(
    fetch: Fetch<Input, Value>,
    options?: ResourceOptions<Input>,
): ResourceHook<Input, Value> {
    const resource = createResource(fetch, options as ResourceOptions<Input>);

    const hook = (input: Input): Value => {
        const hashedInput = resource.hashInput(input);
        const resourceValue = resource.read(input);
        const [value, setValue] = useState(resourceValue);

        useEffect(() => {
            resource.subscribe(input, (newValue) => {
                setValue(newValue);
            });
        }, [hashedInput]);

        return value;
    };

    hook.resource = resource;

    return hook;
}
