import { css, cx } from "emotion";
import React, { MouseEventHandler, ReactNode } from "react";

export interface SquaresGridCellProps {
    children?: ReactNode;
    className?: string;
    onClick?: MouseEventHandler;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
}

const classes = {
    cell: css`
        position: relative;
        border: 1px solid #000000;
        width: 100px;
        height: 100px;
        text-align: center;
        vertical-align: middle;
    `,

    pointer: css`
        cursor: pointer;
    `
};

export const SquaresGridCell = (props: SquaresGridCellProps) => {
    const className = cx(classes.cell, props.className, props.onClick && classes.pointer);

    return (
        <td
            className={className}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            {props.children}
        </td>
    );
};
