import axios from "axios";
import { Game, Match } from "./contracts";

export const api = {
    listGames: async (year: string): Promise<Game[]> => {
        const response = await axios.get<{ value: Game[] }>(`/api/years/${year}/games`);
        return response.data.value;
    },

    getGame: async (year: string, name: string): Promise<Game> => {
        const response = await axios.get<Game>(`/api/years/${year}/games/${name}`);
        return response.data;
    },

    listMatches: async (year: string): Promise<Match[]> => {
        const response = await axios.get<{ value: Match[] }>(`/api/years/${year}/matches`);
        return response.data.value;
    },
};
