import { Game } from "../api";
import { useGames } from "./useGames";

export const useGame = (year: string, name: string): Game => {
    const games = useGames(year);

    const foundGame = games.find((game) => game.year === year && game.name === name);

    if (!foundGame) {
        throw new Error(`Game ${year}-${name} not found!`);
    }

    return foundGame;
};
