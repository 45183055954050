import { css } from "emotion";
import React, { ReactNode } from "react";
import { Col, Container, Row } from "reactstrap";
import { Game } from "../../api";
import { GamesListCard } from "./GameListCard";

export interface GamesListSectionProps {
    title: string;
    games: Game[];
}

const classes = {
    section: css`
        margin-bottom: 64px;
    `,

    title: css`
        margin-bottom: 32px;
    `,

    container: css`
        padding-left: 0;
        padding-right: 0;
    `,
};

export const GamesListSection = (props: GamesListSectionProps) => {
    const { title, games } = props;

    return (
        <React.Fragment>
            {games && games.length && (
                <div className={classes.section}>
                    <h3 className={classes.title}>{title}</h3>
                    <Container fluid={true} className={classes.container}>
                        <Row>
                            {games.map((game) => {
                                return (
                                    <Col key={game.name} xs="12" md="6" lg="4" xl="3">
                                        <GamesListCard key={game.name} game={game} />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </div>
            )}
        </React.Fragment>
    );
};
