import { flatten, range, uniq } from "lodash";
import { Dictionary, NumberDictionary } from ".";
import { Game, Match } from "../api";

export interface PlayerWinningsInfo {
    player: string;
    numberOfSquares: number;
    buyIn: number;
    winnings: number;
    profit: number;
}

export function getUniquePlayers(game: Game): string[] {
    return uniq(flatten(game.players).filter(player => !!player));
}

export function getPayoutsAsArray(game: Game): number[] {
    if (game.gameType === "NCAATournament") {
        return [
            game.payoutPerRound["1"],
            game.payoutPerRound["2"],
            game.payoutPerRound["3"],
            game.payoutPerRound["4"],
            game.payoutPerRound["5"],
            game.payoutPerRound["6"]
        ];
    } else {
        return [game.payoutPerRound["1"], game.payoutPerRound["2"], game.payoutPerRound["3"], game.payoutPerRound["4"]];
    }
}

export function getWinningsPerSquare(game: Game, matches: Match[]): number[][] {
    const winnings = range(10).map(() => Array(10).fill(0));

    iterateGameMatches(game, matches, (match, x, y) => {
        const payout = game.payoutPerRound[`${match.round}`];
        winnings[x][y] += payout;
    });

    return winnings;
}

export function getWinningPlayerByMatch(game: Game, matches: Match[]): Dictionary<string> {
    const winningPlayers: Dictionary<string> = {};

    iterateGameMatches(game, matches, (match, x, y) => {
        winningPlayers[match.globalId] = game.players[x][y];
        console.log("XY", match.score1, match.score2, x, y);
    });

    return winningPlayers;
}

export function getWinningsInfoByPlayer(game: Game, matches: Match[]): Dictionary<PlayerWinningsInfo> {
    const infoByPlayer: Dictionary<PlayerWinningsInfo> = {};

    game.players.forEach(players => {
        players.forEach(player => {
            if (!infoByPlayer[player]) {
                infoByPlayer[player] = {
                    player,
                    numberOfSquares: 0,
                    buyIn: 0,
                    winnings: 0,
                    profit: 0
                };
            }

            infoByPlayer[player].numberOfSquares += 1;
            infoByPlayer[player].buyIn += game.costPerSquare;
            infoByPlayer[player].profit -= game.costPerSquare;
        });
    });

    iterateGameMatches(game, matches, (match, x, y) => {
        const payout = game.payoutPerRound[`${match.round}`];
        const player = game.players[x][y];

        infoByPlayer[player].winnings += payout;
        infoByPlayer[player].profit += payout;
    });

    return infoByPlayer;
}

function iterateGameMatches(game: Game, matches: Match[], iteratee: (match: Match, x: number, y: number) => void) {
    const xByWinningNumbers: NumberDictionary<number> = {};
    game.winningTeamNumbers.map((winningNumber, index) => {
        xByWinningNumbers[winningNumber] = index;
    });

    const yByLosingNumbers: NumberDictionary<number> = {};
    game.losingTeamNumbers.map((losingNumber, index) => {
        yByLosingNumbers[losingNumber] = index;
    });
    console.log(yByLosingNumbers);

    (matches || [])
        .filter(match => match.completed && (match.score1 != null && match.score1 != undefined) && (match.score2 != null && match.score2 != undefined))
        .forEach(match => {
            const useScore1 = match.score1 > match.score2 || game.gameType === "SuperBowl";
            const [winningTeamScore, losingTeamScore] = useScore1
                ? [match.score1, match.score2]
                : [match.score2, match.score1];

            const winningTeamDigit = winningTeamScore % 10;
            const losingTeamDigit = losingTeamScore % 10;

            const x = xByWinningNumbers[winningTeamDigit];
            const y = yByLosingNumbers[losingTeamDigit];

            // TODO: X and Y are backwards. Fix at some point!
            iteratee(match, y, x);
        });
}
