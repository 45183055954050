import React, { ReactNode } from "react";
import { AvatarBase } from "./AvatarBase";
import { getBackgroundColor, getInitials } from "./helpers";

export interface AvatarProps {
    name: string;
}

export const Avatar = (props: AvatarProps) => {
    const { name } = props;

    const initials = getInitials(name);
    const backgroundColor = getBackgroundColor(name);

    return <AvatarBase text={initials} tooltip={name} backgroundColor={backgroundColor} />;
};
