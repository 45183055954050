import { css } from "emotion";
import { orderBy } from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Table } from "reactstrap";
import { Game, Match } from "../../api";
import { getWinningsInfoByPlayer } from "../../utils";
import { Icon } from "../Icon/Icon";

export interface PlayersListProps {
    game: Game;
    matches: Match[];
}

const classes = {
    green: css`
        color: green;
    `,

    red: css`
        color: red;
    `,
};

export const PlayersList = (props: PlayersListProps) => {
    const { game, matches } = props;

    const winningsInfoByPlayer = useMemo(() => getWinningsInfoByPlayer(game, matches), [game, matches]);

    const sortedWinningsInfo = useMemo(() => {
        const items = Object.keys(winningsInfoByPlayer).map((player) => winningsInfoByPlayer[player]);
        orderBy(items, ["winnings"], ["desc"]);
        return items;
    }, [winningsInfoByPlayer]);

    return (
        <Table striped={true}>
            <thead>
                <tr>
                    <th>Players</th>
                    <th>Squares</th>
                    <th>Winnings</th>
                    <th>Profit</th>
                </tr>
            </thead>
            <tbody>
                {sortedWinningsInfo.map((info) => {
                    const winningsClassName = info.winnings > 0 ? classes.green : "";
                    const profitClassName = info.profit > 0 ? classes.green : info.profit < 0 ? classes.red : "";

                    return (
                        <tr key={info.player}>
                            <td>{info.player}</td>
                            <td>{info.numberOfSquares}</td>
                            <td className={winningsClassName}>{`$${info.winnings}`}</td>
                            <td className={profitClassName}>{`$${info.profit}`}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
