import { Dictionary } from "lodash";
import React from "react";
import { Game } from "../api";
import { GamesPage } from "../components/GamesPage";
import { useGames, useSetPageTitle } from "../hooks";

export const GamesPageContainer = () => {
    const currentYear = new Date().getFullYear();
    const gamesByYear: Dictionary<Game[]> = {};

    for (let year = 2018; year <= currentYear; year++) {
        const games = useGames(year.toString()).filter(game => game.gameStarted);
        gamesByYear[year] = games;
    }

    useSetPageTitle("Pools");

    return <GamesPage gamesByYear={gamesByYear} />;
};
