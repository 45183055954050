import React, { ReactNode } from "react";
import { Dictionary } from "../utils";

export interface GlobalStateContextValue {
    globalState: Dictionary<GlobalStateEntry>;
    setGlobalState: (state: Dictionary<GlobalStateEntry>) => void;
}

export interface GlobalStateEntry {
    value: any;
}

export const GlobalStateContext = React.createContext<GlobalStateContextValue>(null as any);

export interface GlobalStateProviderProps {
    children: ReactNode;
}

export class GlobalStateProvider extends React.Component<GlobalStateProviderProps, GlobalStateContextValue> {
    constructor(props: GlobalStateProviderProps) {
        super(props);

        this.state = {
            globalState: {},
            setGlobalState: (state) => this.setState({ globalState: state }),
        };
    }

    public render() {
        return <GlobalStateContext.Provider value={this.state}>{this.props.children}</GlobalStateContext.Provider>;
    }
}
