import React, { ReactNode } from "react";
import { GamesListSection, GamesListSectionProps } from "./GameListSection";

export interface GamesListProps {
    sections: GamesListSectionProps[];
}

export const GamesList = (props: GamesListProps) => {
    const { sections } = props;

    return (
        <React.Fragment>
            {sections.map((section) => {
                return <GamesListSection key={section.title} title={section.title} games={section.games} />;
            })}
        </React.Fragment>
    );
};
