import { css, cx } from "emotion";
import { orderBy } from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Table } from "reactstrap";
import { Game, Match } from "../../api";
import { gamesResource } from "../../data";
import { getWinningPlayerByMatch } from "../../utils";
import { getTeamLogoUrl } from "../../utils/teamsUtils";

export interface MatchesListProps {
    game: Game;
    matches: Match[];
}

const classes = {
    winner: css`
        font-weight: bold;
    `,

    team: css`
        line-height: 32px;
        margin-bottom: 16px;

        img {
            width: 32px;
            height: 32px;
            margin-right: 16px;
        }
    `,
};

export const MatchesList = (props: MatchesListProps) => {
    const { game, matches } = props;

    let roundNames: string[] = [];

    if (game.gameType === "NCAATournament") {
        roundNames = ["Play-in", "Round 1", "Round 2", "Sweet Sixteen", "Elite Eight", "Final Four", "Championship"];
    } else if (game.gameType === "SuperBowl") {
        roundNames = ["", "First Quarter", "Half Time", "Third Quarter", "Final Score"];
    }

    const sortedMatches = useMemo(() => {
        return orderBy(matches, ["round"], ["desc"]);
    }, [game, matches]);

    const isCompletedPage = sortedMatches && sortedMatches.length > 0 ? sortedMatches[0].completed : false;

    const winningPlayersByMatch = useMemo(() => getWinningPlayerByMatch(game, sortedMatches), [game, sortedMatches]);

    const headerText = isCompletedPage ? "Winning Player (Amount)" : "Live Details";

    return (
        <Table striped={true}>
            <thead>
                <tr>
                    <th>Round</th>
                    <th>Score</th>
                    <th>{headerText}</th>
                </tr>
            </thead>
            <tbody>
                {sortedMatches.map((match) => {
                    // const team1LogoUrl = getTeamLogoUrl(match.team1EspnId);
                    // const team2LogoUrl = getTeamLogoUrl(match.team2EspnId);
                    const team1LogoUrl = match.team1LogoUri;
                    const team2LogoUrl = match.team2LogoUri;

                    let team1ClassName = "";
                    let team2ClassName = "";

                    if (game.gameType === "NCAATournament" && isCompletedPage) {
                        team1ClassName = match.team1Winner ? classes.winner : "";
                        team2ClassName = match.team2Winner ? classes.winner : "";
                    }

                    const team1Seed = !!match.team1Seed ? match.team1Seed : "";
                    const team2Seed = !!match.team2Seed ? match.team2Seed : "";

                    const winningPlayer = winningPlayersByMatch[match.globalId];
                    const winningAmount = game.payoutPerRound[`${match.round}`];

                    let detailsText = "";

                    if (isCompletedPage) {
                        const hasAmount = winningAmount > 0 && !!winningPlayer;
                        detailsText = hasAmount ? `${winningPlayer} ($${winningAmount})` : "-";
                    } else {
                        if (match.currentPeriod === "HALFTIME") {
                            detailsText = "Halftime";
                        } else {
                            detailsText = `${match.currentClock} - ${match.currentPeriod}`;
                        }
                    }

                    const score1 = (match.score1 != null || match.score1 != undefined) ? match.score1 : "N/A";
                    const score2 = (match.score2 != null || match.score2 != undefined) ? match.score2 : "N/A";

                    return (
                        <tr key={match.globalId}>
                            <td>{roundNames[match.round] || match.round}</td>
                            <td>
                                <div className={cx(classes.team, team1ClassName)}>
                                    {parseInt(match.year, 10) >= 2019 && team1LogoUrl && (
                                        <img className="d-none d-sm-inline" src={team1LogoUrl} />
                                    )}
                                    <span>{`${team1Seed} ${match.team1} - ${score1}`}</span>
                                </div>
                                <div className={cx(classes.team, team2ClassName)}>
                                    {parseInt(match.year, 10) >= 2019 && team2LogoUrl && (
                                        <img className="d-none d-sm-inline" src={team2LogoUrl} />
                                    )}
                                    <span>{`${team2Seed} ${match.team2} - ${score2}`}</span>
                                </div>
                            </td>
                            <td>{detailsText}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
