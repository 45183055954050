import React from "react";
import { GamePage } from "../components/GamePage";
import { useGame, useRouteParams, useSetPageTitle } from "../hooks";
import { useMatches } from "../hooks/useMatches";

export const GamePageContainer = () => {
    const { year, name } = useRouteParams<{ year: string; name: string }>();

    const game = useGame(year, name);
    const matches = useMatches(year).filter((match) => match.gameType === game.gameType);

    let title = game.displayName;
    if (game.gameType === "NCAATournament") {
        title = game.displayName + " - NCAA Tournament";
    } else if (game.gameType === "SuperBowl") {
        title = game.displayName + " - Super Bowl";
    }

    useSetPageTitle(title);

    return <GamePage game={game} matches={matches} />;
};
