import React, { ReactNode, Suspense } from "react";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { Page } from "../components/Page";
import { usePageTitle } from "../hooks";

export interface PageProps {
    children: ReactNode;
}

export const PageContainer = (props: PageProps) => {
    const { children } = props;

    const [pageTitle] = usePageTitle();

    return (
        <Page>
            <Page.Header brand="Duel Squares" pageTitle={pageTitle} />
            <Page.Content>
                <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
            </Page.Content>
        </Page>
    );
};
