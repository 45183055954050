import { css } from "emotion";
import { flatten, uniq } from "lodash";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Game } from "../../api";
import { getPayoutsAsArray, getUniquePlayers } from "../../utils";
import { AvatarList } from "../Avatar/AvatarList";

export interface GamesListCardProps {
    game: Game;
}

const classes = {
    link: css`
        color: inherit !important;
        text-decoration: none !important;
    `,

    card: css`
        margin-bottom: 16px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);
        cursor: pointer;

        &:hover {
            box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
                0px 1px 14px 0px rgba(0, 0, 0, 0.12);
        }

        h6 {
            margin-bottom: 16px;
            font-size: 14px;
            color: grey;
            text-transform: uppercase;
        }
    `,

    cardBody: css`
        font-size: 14px;

        > div {
            margin-bottom: 32px;
        }
    `,

    gameNotStartedMessage: css`
        font-style: italic;
    `,
};

export const GamesListCard = (props: GamesListCardProps) => {
    const { game } = props;

    let title = game.displayName;
    if (game.gameType === "NCAATournament") {
        title = game.displayName + " - NCAA Tournament";
    } else if (game.gameType === "SuperBowl") {
        title = game.displayName + " - Super Bowl";
    }

    const subtitle = `$${game.costPerSquare} per square`;

    const players = getUniquePlayers(game);

    const payoutString = getPayoutsAsArray(game)
        .map((payout) => "$" + payout)
        .join(" / ");

    return (
        <Link className={classes.link} to={`/years/${game.year}/games/${game.name}`}>
            <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                    <div>
                        <CardTitle>
                            <h4>{title}</h4>
                        </CardTitle>
                        <CardSubtitle>{subtitle}</CardSubtitle>
                    </div>
                    <div className="d-none d-xl-block">
                        <h6>Payouts</h6>
                        <span>{payoutString}</span>
                    </div>
                    <div>
                        <h6>Players</h6>
                        {game.gameStarted ? (
                            <AvatarList names={players} count={6} />
                        ) : (
                            <div className={classes.gameNotStartedMessage}>Game not started</div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </Link>
    );
};
