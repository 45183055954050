import EventEmitter from "eventemitter3";

/**********************************************************************************************************************
 *  Experimental react-cache implementation
 ***********************************************************************************************************************
 *  This is an alternate implementation of https://github.com/facebook/react/tree/master/packages/react-cache
 *  that adds adds functionality to (1) write new values to the cache and (2) subscribe to cache changes.
 *
 *  Note that this will most likely be obsolete in the future once the Context.write feature is finalized,
 *  and this code should be updated to use the official react-cache package at that time.
 **********************************************************************************************************************/

/* tslint:disable max-classes-per-file*/

export interface Resource<Input, Value> {
    read: (input: Input) => Value;
    write: (input: Input, value: Value) => void;
    subscribe: (input: Input, callback: (value: Value) => void) => () => void;
    hashInput(input: Input): string | number;
}

export interface ResourceOptions<Input> {
    hashInput: (input: Input) => string | number;
}

export type Fetch<Input, Value> = (input: Input) => PromiseLike<Value>;

interface CacheEntry<Value> {
    promise: PromiseLike<Value>;
    status: "pending" | "resolved" | "rejected";
    value: Value;
    error: any;
}

export function createResource<Input extends string | number, Value>(
    fetch: Fetch<Input, Value>,
): Resource<Input, Value>;
export function createResource<Input, Value>(
    fetch: Fetch<Input, Value>,
    options: ResourceOptions<Input>,
): Resource<Input, Value>;
export function createResource<Input, Value>(
    fetch: Fetch<Input, Value>,
    options?: ResourceOptions<Input>,
): Resource<Input, Value> {
    const defaultOptions: Partial<ResourceOptions<Input>> = {
        hashInput: (input) => input as any,
    };

    const completeOptions = Object.assign({}, defaultOptions, options);
    return new ResourceImpl(fetch, completeOptions);
}

class ResourceImpl<Input, Value> implements Resource<Input, Value> {
    public hashInput: (input: Input) => string | number;

    private fetch: Fetch<Input, Value>;
    private cache: SubscribableCache<Input, CacheEntry<Value>>;

    constructor(fetch: Fetch<Input, Value>, options: ResourceOptions<Input>) {
        this.fetch = fetch;
        this.hashInput = options.hashInput;
        this.cache = new SubscribableCache(options.hashInput);
    }

    public read(input: Input): Value {
        const cacheEntry = this.getCacheEntry(input);

        switch (cacheEntry.status) {
            case "pending":
                throw cacheEntry.promise;

            case "rejected":
                throw cacheEntry.error;

            case "resolved":
                return cacheEntry.value;
        }
    }

    public write(input: Input, value: Value) {
        const cacheEntry: CacheEntry<Value> = {
            promise: Promise.resolve(value),
            status: "resolved",
            value,
            error: undefined,
        };

        this.cache.set(input, cacheEntry);
    }

    public subscribe(input: Input, callback: (value: Value) => void): () => void {
        return this.cache.subscribe(input, (cacheEntry) => {
            if (cacheEntry.status === "resolved") {
                callback(cacheEntry.value);
            }
        });
    }

    private getCacheEntry(input: Input): CacheEntry<Value> {
        const cacheEntry = this.cache.get(input);
        if (cacheEntry) {
            return cacheEntry;
        }

        const promise = this.fetch(input);

        const newCacheEntry: CacheEntry<Value> = {
            promise,
            status: "pending",
            value: undefined as any,
            error: undefined,
        };

        promise.then(
            (value) => {
                newCacheEntry.value = value;
                newCacheEntry.status = "resolved";
            },
            (error) => {
                newCacheEntry.error = error;
                newCacheEntry.status = "rejected";
            },
        );

        this.cache.set(input, newCacheEntry);

        return newCacheEntry;
    }
}

class SubscribableCache<K, V> {
    private cache: Map<string | number, V> = new Map();
    private eventEmitter = new EventEmitter();

    constructor(private cacheKey: (key: K) => string | number) {}

    public get(key: K): V | undefined {
        const cacheKey = this.cacheKey(key);
        return this.cache.get(cacheKey);
    }

    public set(key: K, value: V): void {
        const cacheKey = this.cacheKey(key);
        this.cache.set(cacheKey, value);
        const eventName = this.getEventName(key);
        this.eventEmitter.emit(eventName, value);
    }

    public subscribe(key: K, callback: (value: V) => void): () => void {
        const eventName = this.getEventName(key);

        const listener = (value: V) => callback(value);
        this.eventEmitter.on(eventName, listener);

        return () => {
            this.eventEmitter.off(eventName, listener);
        };
    }

    private getEventName(key: K): string {
        return `${this.cacheKey(key)}`;
    }
}
