import { css } from "emotion";
import { range } from "lodash";
import React, { ReactNode } from "react";
import { Game } from "../../api";
import { SquaresGridCell } from "./SquaresGridCell";
import { SquaresGridRow } from "./SquaresGridRow";

export interface SquaresGridProps {
    topNumbers: number[];
    leftNumbers: number[];
    topName: string;
    leftName: string;
    renderCell: (x: number, y: number) => ReactNode | undefined;
}

const classes = {
    numberCell: css`
        background-color: #cacfd2;
        color: #000000;
        font-size: 24px;
        font-weight: bold;
    `,

    divider: css`
        height: 2px;
        background-color: black;
        transform: rotate(45deg);
    `,

    winnerLabel: css`
        font-size: medium;
        transform: rotate(45deg);
        position: relative;
        left: 10px;
        top: 15px;
    `,

    loserLabel: css`
        font-size: medium;
        transform: rotate(45deg);
        position: relative;
        left: -12px;
        top: -15px;
    `,
};

export const SquaresGrid = (props: SquaresGridProps) => {
    const topNumbers = props.topNumbers || [];
    const leftNumbers = props.leftNumbers || [];
    const cellRange = range(10);

    let leftNumberIndex = 0;

    return (
        <table>
            <tbody>
                <SquaresGridRow>
                    <SquaresGridCell className={classes.numberCell}>
                        <div className={classes.winnerLabel}>{props.topName}</div>
                        <hr className={classes.divider} />
                        <div className={classes.loserLabel}>{props.leftName}</div>
                    </SquaresGridCell>
                    {cellRange.map((index) => {
                        return (
                            <SquaresGridCell className={classes.numberCell} key={index}>
                                {topNumbers[index]}
                            </SquaresGridCell>
                        );
                    })}
                </SquaresGridRow>
                {cellRange.map((rowNumber) => {
                    return (
                        <SquaresGridRow key={rowNumber}>
                            <SquaresGridCell className={classes.numberCell} key={leftNumberIndex}>
                                {leftNumbers[leftNumberIndex++]}
                            </SquaresGridCell>
                            {cellRange.map((columnNumber) => {
                                return props.renderCell(rowNumber, columnNumber);
                            })}
                        </SquaresGridRow>
                    );
                })}
            </tbody>
        </table>
    );
};

SquaresGrid.Cell = SquaresGridCell;
