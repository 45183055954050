import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { GamePageContainer, GamesPageContainer, PageContainer } from "./containers";
import { GlobalStateProvider } from "./context";

export const App = () => {
    return (
        <GlobalStateProvider>
            <BrowserRouter>
                <PageContainer>
                    <Switch>
                        <Route exact={true} path="/" component={GamesPageContainer} />
                        <Route path="/years/:year/games/:name" component={GamePageContainer} />
                        <Redirect to="/" />
                    </Switch>
                </PageContainer>
            </BrowserRouter>
        </GlobalStateProvider>
    );
};
