import useReactRouter from "use-react-router";

export function useRouteParams<TParams>(): TParams {
    const { match } = useReactRouter<TParams>();
    return match.params;
}

export function useRouteUrl(): string {
    const { match } = useReactRouter();
    return match.url;
}

export function useRoutePath(): string {
    const { match } = useReactRouter();
    return match.path;
}
