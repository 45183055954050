import React, { ReactNode } from "react";
import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";

export interface PageProps {
    children: ReactNode;
}

export const Page = (props: PageProps) => {
    return <React.Fragment>{props.children}</React.Fragment>;
};

Page.Header = PageHeader;
Page.Content = PageContent;
