import { css, cx } from "emotion";
import React, { ReactNode, useMemo, useState } from "react";
import { Game, Match } from "../../api";
import { getWinningsPerSquare } from "../../utils";
import { SquaresGrid } from "../SquaresGrid";

export interface GameGridProps {
    game: Game;
    matches: Match[];
}

const classes = {
    selected: css`
        background-color: lightgreen;
    `,

    hover: css`
        background-color: #eeeeee;
    `,

    player: css`
        position: absolute;
        top: 8px;
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: 14px;
    `,

    winnings: css`
        margin-top: 16px;
        font-size: 24px;
    `,

    green: css`
        color: green;
    `,
};

export const GameGrid = (props: GameGridProps) => {
    const { game, matches } = props;

    const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);
    const [hoveredPlayer, setHoveredPlayer] = useState<string | null>(null);

    const winnings = useMemo(() => getWinningsPerSquare(game, matches), [game, matches]);

    let topName = "WINNER";
    let leftName = "LOSER";
    if (game.gameType === "SuperBowl") {
        topName = game.team1Name;
        leftName = game.team2Name;
    }

    return (
        <SquaresGrid
            topNumbers={game.winningTeamNumbers}
            leftNumbers={game.losingTeamNumbers}
            topName={topName}
            leftName={leftName}
            renderCell={(x, y) => {
                const cellPlayer = game.players[x][y];

                let cellClassName: string | undefined;

                if (hoveredPlayer) {
                    const isCellHovered = cellPlayer && hoveredPlayer && cellPlayer === hoveredPlayer;
                    cellClassName = cx(cellClassName, isCellHovered && classes.hover);
                }

                if (selectedPlayer) {
                    const isCellSelected = cellPlayer && selectedPlayer && cellPlayer === selectedPlayer;
                    cellClassName = cx(cellClassName, isCellSelected && classes.selected);
                }

                return (
                    <SquaresGrid.Cell
                        className={cellClassName}
                        onClick={() => setSelectedPlayer(game.players[x][y])}
                        onMouseEnter={() => setHoveredPlayer(game.players[x][y])}
                        onMouseLeave={() => setHoveredPlayer(null)}
                    >
                        <div className={classes.player}>{cellPlayer}</div>
                        <div className={cx(classes.winnings, !!winnings[x][y] && classes.green)}>
                            {winnings[x][y] ? `$${winnings[x][y]}` : "-"}
                        </div>
                    </SquaresGrid.Cell>
                );
            }}
        />
    );
};
