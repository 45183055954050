import { faTablets } from "@fortawesome/free-solid-svg-icons";
import React, { ReactNode } from "react";
import { Game, Match } from "../../api";
import { GameGrid } from "../GameGrid";
import { MatchesList } from "../MatchesList";
import { PlayersList } from "../PlayersList";
import { Tab, Tabs } from "../Tabs";

export interface GamePageProps {
    game: Game;
    matches: Match[];
}

export const GamePage = (props: GamePageProps) => {
    const { game, matches } = props;

    const completedMatches = matches.filter(match => match.completed);
    const liveMatches = matches.filter(match => !match.completed);

    const tabs: Tab[] = [
        {
            title: "Grid",
            renderContent: () => {
                return <GameGrid game={game} matches={matches} />;
            }
        },
        {
            title: game.gameType === "NCAATournament" ? "Finished Games" : "Results",
            renderContent: () => {
                return <MatchesList game={game} matches={completedMatches} />;
            }
        }
    ];

    if (!!liveMatches && liveMatches.length > 0) {
        tabs.push({
            title: game.gameType === "NCAATournament" ? "Live Games" : "Live Results",
            renderContent: () => {
                return <MatchesList game={game} matches={liveMatches} />;
            }
        });
    }

    tabs.push({
        title: "Players",
        renderContent: () => {
            return <PlayersList game={game} matches={completedMatches} />;
        }
    });

    return (
        <React.Fragment>
            <Tabs tabs={tabs} />
        </React.Fragment>
    );
};
