import { useCallback, useContext, useEffect } from "react";
import { GlobalStateContext } from "../context/GlobalStateProvider";

export const useGlobalState = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
    const { globalState, setGlobalState } = useContext(GlobalStateContext);

    const setValue = useCallback(
        (newValue: T) => {
            setGlobalState({
                ...globalState,
                [key]: { value: newValue },
            });
        },
        [key],
    );

    const entry = globalState[key];

    useEffect(() => {
        if (!entry) {
            setValue(initialValue);
        }
    }, [key]);

    const value = entry ? entry.value : initialValue;

    return [value, setValue];
};
