import { css } from "emotion";
import React from "react";
import { Link } from "react-router-dom";

export interface PageHeaderProps {
    brand: string;
    pageTitle?: string;
}

export interface PageHeaderBreadcrumb {
    text: string;
    path: string;
    active?: boolean;
}

const classes = {
    nav: css`
        width: 100%;
        height: 64px;
        position: fixed;
        z-index: 10000;
        background-color: black;
        color: white;
    `,

    brand: css`
        display: inline-block;
        height: 100%;
        color: white !important;
        text-decoration: none !important;
        margin-left: 24px;
        margin-right: 24px;

        h1 {
            display: inline-block;
            line-height: 64px;
            font-size: 24px;
        }
    `,

    pageTitle: css`
        display: inline-block;
        color: white;
        font-size: 18px;
    `,

    breadcrumbs: css`
        display: inline-block;

        .breadcrumb {
            background: none;
        }

        .breadcrumb-item {
            a {
                color: white;
                font-size: 18px;
                text-decoration: none;
            }

            &.active {
                font-weight: bold;
            }
        }

        .breadcrumb-item + .breadcrumb-item::before {
            color: white;
        }
    `,
};

export const PageHeader = (props: PageHeaderProps) => {
    const { brand, pageTitle } = props;

    return (
        <nav className={classes.nav}>
            <Link to="/" className={classes.brand}>
                <h1>{brand}</h1>
            </Link>
            <h2 className={classes.pageTitle}>{pageTitle}</h2>
        </nav>
    );
};
