import { useContext, useEffect } from "react";
import { useGlobalState } from "./useGlobalState";

export const usePageTitle = (): [string, (pageTitle: string) => void] => {
    return useGlobalState("pageTitle", "");
};

export const useSetPageTitle = (newPageTitle: string): void => {
    const [pageTitle, setPageTitle] = usePageTitle();
    useEffect(() => setPageTitle(newPageTitle), [newPageTitle]);
};
