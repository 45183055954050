import { Dictionary } from "lodash";
import React from "react";
import { Game } from "../../api";
import { GamesList } from "../GamesList";

export interface GamesPageProps {
    gamesByYear: Dictionary<Game[]>;
}

export const GamesPage = (props: GamesPageProps) => {
    const { gamesByYear } = props;

    const years = Object.keys(gamesByYear);
    years.sort().reverse();

    const sections = years
        .filter((year) => {
            return !!gamesByYear[year].length;
        })
        .map((year) => {
            return {
                title: year,
                games: gamesByYear[year],
            };
        });

    return <GamesList sections={sections} />;
};
