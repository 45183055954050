import { css } from "emotion";
import React, { ReactNode } from "react";

export interface PageContentProps {
    children: ReactNode;
}

const classes = {
    root: css`
        padding-top: 96px;
        padding-right: 48px;
        padding-left: 48px;
        padding-bottom: 48px;
    `,
};

export const PageContent = (props: PageContentProps) => {
    return <div className={classes.root}>{props.children}</div>;
};
