import { useEffect } from "react";
import { createResourceHook } from ".";
import { Fetch, ResourceOptions } from "./createResource";

export interface ApiHookOptions {
    pollInterval?: number;
}

export function createApiHook<Input extends string | number, Value>(
    fetch: Fetch<Input, Value>,
    options?: ApiHookOptions,
): (input: Input) => Value;
export function createApiHook<Input, Value>(
    fetch: Fetch<Input, Value>,
    options: ApiHookOptions & ResourceOptions<Input>,
): (input: Input) => Value;
export function createApiHook<Input, Value>(
    fetch: Fetch<Input, Value>,
    options?: ApiHookOptions | (ApiHookOptions & ResourceOptions<Input>),
): (input: Input) => Value {
    const useResource = createResourceHook(fetch, options as ResourceOptions<Input>);
    const resource = useResource.resource;

    let pollIntervalId = 0;
    let pollRefCount = 0;

    const hook = (input: Input): Value => {
        const value = useResource(input);

        useEffect(() => {
            if (options && options.pollInterval) {
                if (pollRefCount === 0) {
                    pollIntervalId = window.setInterval(async () => {
                        const newValue = await fetch(input);
                        resource.write(input, newValue);
                    }, options.pollInterval);
                }

                pollRefCount++;

                let isDisposed = false;
                return () => {
                    if (!isDisposed) {
                        isDisposed = true;

                        pollRefCount--;

                        if (pollRefCount === 0) {
                            window.clearInterval(pollIntervalId);
                        }
                    }
                };
            }
        }, [resource.hashInput(input)]);

        return value;
    };

    return hook;
}
