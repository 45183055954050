import { css, cx } from "emotion";
import React, { ReactNode } from "react";
import { getTextColor } from "./helpers";

export interface AvatarBaseProps {
    text: string;
    tooltip?: string;
    backgroundColor?: string;
}

const classes = {
    root: css`
        display: inline-block;
    `,

    container: css`
        display: table;
        width: 48px;
        height: 48px;
        border-radius: 50%;
    `,

    text: css`
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        font-size: 18px;
    `,
};

export const AvatarBase = (props: AvatarBaseProps) => {
    const text = props.text;
    const tooltip = props.tooltip || text;

    const backgroundColor = props.backgroundColor || "#E5E7E9";
    const textColor = getTextColor(backgroundColor);

    const colorCssClass = css`
        background-color: ${backgroundColor};
        color: ${textColor};
    `;

    return (
        <div className={classes.root}>
            <div className={cx(classes.container, colorCssClass)} title={tooltip}>
                <div className={classes.text}>{text}</div>
            </div>
        </div>
    );
};
